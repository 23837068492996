<template>
  <div>
    <the-wave-background>
      <h1 class="font-weight-regular white--text title">
        Olá
        <b class="text-capitalize">{{ user.nome.split(" ").slice(0, 1).toString().toLowerCase() }}</b
        >!
      </h1>
      <p class="white--text subtitle-2">Bem vindo(a) ao novo Qualicorp QualiVendas</p>
    </the-wave-background>
    <v-container class="pa-4 pt-0">
      <v-row no-gutters class="mb-0">
        <v-col>
          <h2 class="primary--text title font-weight-regular">O que você deseja fazer?</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-2" cols="6" md="4">
          <base-button-icon-text :data-test-id="'criar-proposta'" icon="mdi-plus-circle" text="Criar Proposta Adesão" :redirecionar="redirecionarCriaProposta" color="primary" dark></base-button-icon-text>
        </v-col>
        <v-col v-if="showPME" class="pa-2" cols="6" md="4">
          <base-button-icon-text :data-test-id="'PME'" icon="mdi-plus-circle" text="Criar Proposta PME" :redirecionar="redirecionarPME" color="#FFB61E" dark></base-button-icon-text>
        </v-col>
        <v-col class="pa-2" cols="6" md="4">
          <base-button-icon-text icon="mdi-file-document" :data-test-id="'acompanhar-proposta'" text="Acompanhar Propostas Adesão" :redirecionar="redirecionarPropostas"></base-button-icon-text>
        </v-col>
        <v-col v-if="showPME" class="pa-2" cols="6" md="4">
          <base-button-icon-text icon="mdi-file-document" :data-test-id="'acompanhar-proposta-pme'" text="Acompanhar Propostas PME" :redirecionar="redirecionarPropostasPME"></base-button-icon-text>
        </v-col>
        <v-col class="pa-2" cols="6" md="4">
          <base-button-icon-text icon="mdi-plus-circle" :data-test-id="'criar-simulacao'" :redirecionar="redirecionarCriarSimulacao" text="Criar Simulação"></base-button-icon-text>
        </v-col>

        <v-col class="pa-2" cols="6" md="4">
          <base-button-icon-text icon="mdi-file-document-edit" :data-test-id="'simulacoes'" :redirecionar="redirecionarSimulacoes" text="Simulações"></base-button-icon-text>
        </v-col>
        <v-col v-if="possuiLeads" class="pa-2" cols="6" md="4">
          <base-button-icon-text icon="mdi-account-group" :redirecionar="redirecionarLeads" :count="leads" :warning="alertaLeads" text="Leads" :key="btnLeads"></base-button-icon-text>
        </v-col>
        <v-col class="pa-2" cols="6" md="4">
          <base-button-icon-text icon="mdi-calendar-month" :data-test-id="'consultar-vigencia'" text="Consultar Vigência" :redirecionar="redirecionarVigencia"></base-button-icon-text>
        </v-col>
        <v-col v-if="exibePlantao" class="pa-2" cols="6" md="4">
          <base-button-icon-text icon="mdi-account-tie" text="Plantão de Leads" :redirecionar="redirecionarPlantaoLeads"></base-button-icon-text>
        </v-col>
        <v-col class="pa-2" cols="6" md="4">
          <base-button-icon-text icon="mdi-share-variant" text="Envio de Boletos" :redirecionar="redirecionarEnvioBoleto"></base-button-icon-text>
        </v-col>
        <v-col class="pa-2" cols="6" md="4">
          <base-button-icon-text icon="mdi-table-check" text="Tabelas de Venda" :redirecionar="redirecionarTabelasVenda"></base-button-icon-text>
        </v-col>
        <v-col v-if="exibeClassificacao" class="pa-2" cols="6" md="4">
          <button-classificacao :classificacao="classificacao" :redirecionar="redirecionarClassificacao" />
        </v-col>

        <!-- <v-col v-if="exibeSwile" class="pa-2" cols="6" md="4">
          <v-btn height="auto" block class="pa-4 d-flex" elevation="3" @click="redirecionarSwileBr" color="#FFFFFF">
            <div class="d-flex flex-column">
              <v-img src="@/assets/img/swile/app W gradient.png" style="left: 5px; width: 43.5px"> </v-img>
              <span class="text-capitalize font-weight-bold text-wrap pa-0 pl-0" style="font-size: 0.7rem">Swile BR</span>
            </div>
            <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex flex-column">
              <span class="text-none font-weight-bold pa-0 pl-4" style="font-size: 0.9rem">Conheça o APP<br />pagamento!</span>
            </div>
          </v-btn>
        </v-col> -->
        <v-col v-if="exibeNiky" class="pa-2" cols="6" md="4">
          <v-btn height="auto" block class="pa-4 d-flex" elevation="3" @click="redirecionarNiky" color="#FFFFFF">
            <div class="d-flex flex-column">
              <v-img src="@/assets/img/niky/niky_logo_simple.png" style="width: 43.5px"> </v-img>
              <span class="text-capitalize font-weight-bold text-wrap pa-0 pl-0" style="font-size: 0.7rem">Niky</span>
            </div>
            <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex flex-column">
              <span class="text-none font-weight-bold pa-0 pl-4" style="font-size: 0.9rem">Conheça o APP<br />pagamento!</span>
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="showDialogNotif" width="500" :key="componentKey">
      <v-card>
        <v-card-title>
          <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Notificação</h2>
          <v-spacer></v-spacer>
          <v-btn icon @click="showDialogNotif = false">
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <h2 class="text-h5">{{ notificacaoSelecionada.titulo }}</h2>
          <h3 class="text-body-1">{{ notificacaoSelecionada.resumo }}</h3>
          <div v-if="notificacaoSelecionada.arquivos && notificacaoSelecionada.arquivos.length > 0" class="container-image mt-5">
            <img :src="notificacaoSelecionada.arquivos[0].url" alt="imagem-notificação" />
          </div>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn color="green" icon :loading="loadingLike" :disabled="loadingDislike" @click="onClickAvaliar(notificacaoSelecionada.guid, true)">
            <v-icon>mdi-thumb-up-outline</v-icon>
          </v-btn>
          <v-btn color="red" icon :loading="loadingDislike" :disabled="loadingLike" @click="onClickAvaliar(notificacaoSelecionada.guid, false)" class="mr-3">
            <v-icon>mdi-thumb-down-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDialogConfirm" width="300">
      <v-card>
        <v-card-title>
          <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Avaliação</h2>
          <v-spacer></v-spacer>
          <v-btn icon @click="showDialogConfirm = false">
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          O que você achou dessa notificação?
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn color="green" icon :loading="loadingLike" :disabled="loadingDislike" @click="onClickAvaliar(notificacaoSelecionada.guid, true)">
            <v-icon>mdi-thumb-up-outline</v-icon>
          </v-btn>
          <v-btn color="red" icon :loading="loadingDislike" :disabled="loadingLike" @click="onClickAvaliar(notificacaoSelecionada.guid, false)" class="mr-3">
            <v-icon>mdi-thumb-down-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDialogAvaliacao" width="650" :key="componentAvaliacaoKey">
      <v-card>
        <v-card-title>
          <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Pesquisa</h2>
          <v-spacer></v-spacer>
          <v-btn icon @click="showDialogAvaliacao = false">
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p>{{ avaliacaoSelecionada.textoPesquisa }}</p>
          <custom-avaliacao :guid="avaliacaoSelecionada.guid" :metodoNotas="avaliacaoSelecionada.metodoNotas" :perguntaPesquisa="avaliacaoSelecionada.perguntaPesquisa" @setAvaliacao="setAvaliacao" class="text-center" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TheWaveBackground from '@/components/TheWaveBackground.vue';
import BaseButtonIconText from '@/components/BaseButtonIconText.vue';
import comunicacoesService from '@/services/comunicacoesService';
import ButtonClassificacao from './ButtonClassificacao.vue';
import CustomAvaliacao from './CustomAvaliacao.vue';
import { findIndex, first, filter, orderBy } from "lodash";

export default {
  name: 'Home',
  data() {
    return {
      avaliacoes: [],
      avaliacaoSelecionada: {},
      notificacoes: [],
      notificacaoSelecionada: {},
      showDialogAvaliacao: false,
      showDialogNotif: false,
      showDialogConfirm: false,
      flagStopShowNotif: false,
      flagStopShowAvaliacao: false,
      leads: '',
      btnLeads: 0,
      alertaLeads: '',
      possuiLeads: false,
      possuiPlantao: false,
      componentKey: 0,
      componentAvaliacaoKey: 0,
      loadingLike: false,
      loadingDislike: false,
    };
  },
  watch: {
    showDialogNotif(value) {
      if (!value && !this.flagStopShowNotif) {
        this.showDialogConfirm = true;
      }
    },
    showDialogConfirm(value) {
      if (!value && !this.flagStopShowNotif) {
        this.setNotificacao(this.notificacaoSelecionada.guid);
      }
    },
    showDialogAvaliacao(value) {
      if (!value && !this.flagStopShowAvaliacao) {
        this.setAvaliacao(this.avaliacaoSelecionada.guid);
      }
    },
  },
  components: { TheWaveBackground, BaseButtonIconText, ButtonClassificacao, CustomAvaliacao },
  computed: {
    ...mapState({
      user: (state) => state.user.profile,
      statusLeads: (state) => state.lead.statusLeads,
      plantaoLeads: (state) => state.lead.plantaoLeads,
    }),
    exibePlantao() {
      return this.possuiLeads || this.possuiPlantao;
    },
    exibeClassificacao() {
      const dominiosQuali = '@qualicorp.com.br,@fornecedor.qualicorp.com.br,@grupoelobeneficios.com.br';
      const {
        contato, tipoCorretor, concentrador, subTipoCorretor,
      } = this.user;
      const [, dominioUsuario] = contato.email.split("@");
      const emailQuali = dominiosQuali.split(',').includes(dominioUsuario);
      return tipoCorretor === 'Externo' && subTipoCorretor !== 'Atendimento' && !emailQuali && !concentrador;
    },
    // exibeSwile() {
    //   const { tipoCorretor } = this.user;
    //   return tipoCorretor && tipoCorretor === 'Externo';
    // },
    exibeNiky() {
      const { tipoCorretor } = this.user;
      return tipoCorretor && tipoCorretor === 'Externo';
    },
    showPME() {
      const { corretoras } = this.user;
      const PMEAdministrado = corretoras.find((item) => item.PMEAdministrado);
      if (PMEAdministrado) return true;
      return false;
    },
    classificacao() {
      const dictionary = {
        Experiente: 'EXPERIENTE',
        Especialista: 'ESPECIALISTA',
        Elite: 'ELITE',
        Imbatível: 'IMBATIVEL',
        Platinum: 'PLATINUM',
      };
      return dictionary[this.user.classificacao] || '';
    },
  },
  async created() {
    this.getNotificacoes();
    this.getInfoLeads();
    this.getInfoPlantao();
  },
  methods: {
    ...mapActions({
      setBlnContinuar: 'cadastroProposta/setBlnContinuar',
      resetDados: 'simulacao/resetDados',
    }),
    redirecionarCriaProposta() {
      this.setBlnContinuar({ flag: false, page: 0 });
      this.$router.push({ name: 'areaLogada.criarProposta' });
    },
    redirecionarPME() {
      this.$router.push({ name: 'areaLogada.PME' });
    },
    redirecionarPropostas() {
      this.$router.push({ name: 'areaLogada.propostas' });
    },
    redirecionarPropostasPME() {
      this.$router.push({ name: 'areaLogada.propostasPME' });
    },
    redirecionarLeads() {
      this.$router.push({ name: 'areaLogada.lead' });
    },
    redirecionarCriarSimulacao() {
      this.resetDados();
      this.$router.push({ name: 'areaLogada.criarSimulacao' });
    },
    redirecionarSimulacoes() {
      this.$router.push({ name: 'areaLogada.simulacoes' });
    },
    redirecionarVigencia() {
      this.$router.push({ name: 'areaLogada.vigencia' });
    },
    redirecionarPlantaoLeads() {
      this.$router.push({ name: 'areaLogada.plantaoLeads' });
    },
    redirecionarEnvioBoleto() {
      this.$router.push({ name: 'areaLogada.envioBoleto' });
    },
    redirecionarTabelasVenda() {
      this.$router.push({ name: 'areaLogada.tabelasVenda' });
    },
    redirecionarClassificacao() {
      this.$router.push({ name: 'areaLogada.classificacaoTamoJunto' });
    },
    // redirecionarSwileBr() {
    //   this.$router.push({ name: 'areaLogada.swileBr' });
    // },
    redirecionarNiky() {
      this.$router.push({ name: 'areaLogada.niky' });
    },
    async getAvaliacoes() {
      try {
        const avaliacoes = await comunicacoesService.getAvaliacaoCorretor();
        const avaliacoesOrdenadas = orderBy(avaliacoes, ["dataCadastro"], ["asc"]);
        this.avaliacoes = filter(avaliacoesOrdenadas, function (avaliacao) {
          return (!avaliacao.tentativas || avaliacao.tentativas <= 5) && !avaliacao.dataPesquisa;
        });

        if (this.avaliacoes && this.avaliacoes.length) {
          this.avaliacaoSelecionada = first(this.avaliacoes);
          this.showDialogAvaliacao = true;
          comunicacoesService.atualizarVisualizadoAvaliacao(this.avaliacaoSelecionada.guid);
        }
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao carregar as avaliações, tente novamente mais tarde',
        });
      }
    },
    setAvaliacao(guid) {
      const index = findIndex(this.avaliacoes, { guid });
      if (this.avaliacoes[index + 1]) {
        this.avaliacaoSelecionada = this.avaliacoes[index + 1];
        comunicacoesService.atualizarVisualizadoAvaliacao(this.avaliacaoSelecionada.guid);
        this.showDialogAvaliacao = true;
      } else {
        this.flagStopShowAvaliacao = true;
        this.showDialogAvaliacao = false;
      }
      this.componentAvaliacaoKey += 1;
    },
    async getNotificacoes() {
      try {
        const notificacoes = await comunicacoesService.getNotificacoes();
        const notificacoesNaoVisualizadas = notificacoes.filter((notificacao) => notificacao.visualizou === null);
        this.notificacoes = orderBy(notificacoesNaoVisualizadas, ["dataCadastro"], ["asc"]);

        if (this.notificacoes && this.notificacoes.length) {
          this.notificacaoSelecionada = first(this.notificacoes);
          comunicacoesService.atualizarVisualizado(this.notificacaoSelecionada.guid);
          this.showDialogNotif = true;
        } else this.getAvaliacoes();
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao carregar as notificações, tente novamente mais tarde',
        });
      }
    },
    setNotificacao(guid) {
      const index = findIndex(this.notificacoes, { guid });
      if (this.notificacoes[index + 1]) {
        this.notificacaoSelecionada = this.notificacoes[index + 1];
        comunicacoesService.atualizarVisualizado(guid);
        this.showDialogNotif = true;
      } else {
        this.flagStopShowNotif = true;
        this.showDialogConfirm = false;
        this.showDialogNotif = false;
        this.getAvaliacoes();
      }
      this.componentKey += 1;
    },
    async onClickAvaliar(guid, avaliacao) {
      try {
        if (avaliacao) this.loadingLike = true;
        else this.loadingDislike = true;
        await comunicacoesService.avaliarNotificacao(guid, avaliacao);
        this.$root.$snackBar.open({
          color: "success",
          message: "Obrigado pelo feedback!",
        });
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao avaliar a notificação, tente novamente mais tarde.",
        });
      } finally {
        if (avaliacao) this.loadingLike = false;
        else this.loadingDislike = false;
        this.setNotificacao(guid);
      }
    },
    async getInfoLeads() {
      try {
        this.leads = '';
        this.leads = this.statusLeads && (this.statusLeads.qtNovosLeads > 0) ? this.statusLeads.qtNovosLeads.toString() : '';
        this.alertaLeads = this.statusLeads && (this.statusLeads.qtAlertasTratamento > 0) ? this.statusLeads.qtAlertasTratamento.toString() : '';
        this.possuiLeads = this.statusLeads ? this.statusLeads.possuiLeads : false;
        this.btnLeads++;
      } catch (error) {
        this.leads = '';
        this.alertaLeads = '';
        this.possuiLeads = true;
        this.btnLeads++;
      }
    },
    async getInfoPlantao() {
      this.possuiPlantao = !!(this.plantaoLeads || []).length;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/variables";

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
  height: 140px;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  height: 0px;
}

.username {
  font-size: 1em;
}

@media only screen and (max-width: 320px) {
  .username {
    font-size: 1em;
  }
}

.container-image img {
  max-height: 300px;
  max-width: 400px;
  @media (max-width: $media-sm) {
    max-width: 100%;
  }
}
</style>
